export default {
    en: {
        "Landing.presentation" : "A Freelancer that makes your life easy",
        "NavBar.about" : "About Me",
        "NavBar.portfolio" : "Porfolio",
        "NavBar.contact" : "Contact Us",
        "Language.button" : "Language",
        "Language.english" : "English",
        "Language.spanish" : "Spanish",
        "ContactMe.title": "Send me a message",
        "ContactMe.description" : "You can contact us with anything related to our Products. We will get in touch with you as soon as possible.",
        "ContactMe.form.name" : "Your Name",
        "ContactMe.form.email" : "Email address",
        "ContactMe.form.phone" : "Phone",
        "ContactMe.form.message" : "Your Message",
        "ContactMe.office" : "Find us at the office",
        "ContactMe.call" : "Give me a ring",
        "ContactMe.button" : "contact Me",
        "Profile.charge" : "Developer",
        "Profile.description" : "Web developer with 6 years of experience in different technologies, passionate about music, video games and anime",
        "AboutMe.section.description" : "Ninja developer specialized in the development of custom software, if it can be done manually it can be automated and optimized, and I am the perfect one for it. Always oriented to the implementation of good practices to deliver a clean code.",
        "Portfolio.title" : "What I've done",
        "Portfolio.pam.description" : "PAM is an audit platform created for Colombian educational institutions, in which the registration of documents and attendance of each student can be kept",
        "Portfolio.ecommerce.description" : "Complete fully customizable online store, product creation, shipping methods, payment methods, inventory statistics among much more.",
        "Portfolio.playlistpuzzle.description" : "Build your Spotify playlist with the help of your friends !!!",
        "Portfolio.lostpets.description" : "Report your pet when it gets lost and let the community help you find it!",
        "Portfolio.button.go" : "Go to demo",
        "ContactMe.warning.title" : "WARNING ALERT: ",
        "ContactMe.warning.message" : "This site is currently under construction, so mail delivery is not yet developed, however, you can send me an email to those described in the next section",
        "FormSubmit.error" : "Sorry, there are some errors in the form.",
        "FormSubmit.success" : "Your message was sent and I will respond as soon as possible",
        "FormSubmit.success.title" : "Great"
    },
    es: {
        "Landing.presentation" : "Un Freelancer que hará tu vida mas fácil",
        "NavBar.about" : "Acerca de mi",
        "NavBar.portfolio" : "Portafolio",
        "NavBar.contact" : "Contáctenos",
        "Language.button" : "Lenguage",
        "Language.english" : "Inglés",
        "Language.spanish" : "Español",
        "ContactMe.title": "Envíame un mensaje",
        "ContactMe.description" : "Puedes contactarnos con cualquier cosa relacionada con nuestros Productos. Nos pondremos en contacto con usted lo antes posible.",
        "ContactMe.form.name" : "Tu Nombre",
        "ContactMe.form.email" : "Correo electrónico",
        "ContactMe.form.phone" : "Celular",
        "ContactMe.form.message" : "Tu Mensaje",
        "ContactMe.office" : "Encuentrame en mi oficina",
        "ContactMe.call" : "Llámame",
        "ContactMe.button" : "Contáctame",
        "Profile.charge" : "Desarrollador",
        "Profile.description" : "Desarrollador web con 6 años de experiencia en diferentes tecnologías, apasionado por la música, los videojuegos y el anime",
        "AboutMe.section.description" : "Ninja developer especializado en el desarrollo de software a la medida, si se puede hacer de manera manual se puede automatizar y optimizar, y yo soy el perfeto para ello. Orientado siempre a la implementación de las buenas prácticas para entregar un código limpio.",
        "Portfolio.title" : "Lo que he hecho",
        "Portfolio.pam.description" : "PAM es una plataforma de auditoria creada para las instituciones educativas colombianas, en la cual se puede llevar el registro de los documentos y asistencia de cada alumno",
        "Portfolio.ecommerce.description" : "Completa tienda online completamente personalizable, creación de productos, métodos de envío, medios de pago, estadisticas de inventario entre mucho más.",
        "Portfolio.playlistpuzzle.description" : "Arma tus playlist de Spotify con la ayuda de tus amigos!!!",
        "Portfolio.lostpets.description" : "Reporta tu mascota cuando se pierda y deja que la comunidad te ayude a encontrarla!!!",
        "Portfolio.button.go" : "Ir a la página demo",
        "ContactMe.warning.title" : "Advertencia: ",
        "ContactMe.warning.message" : "actualmente este sitio se encuentra en construcción por lo cual aún no está desarrollado el envío de correo, sin embargo, puedes enviarme un email  a los que se describen en la próxima sección",
        "FormSubmit.error" : "Lo sentimos, hay algunos errores en el formulario.",
        "FormSubmit.success" : "Tu mensaje fue enviado y te responderé lo mas pronto posible",
        "FormSubmit.success.title" : "Listo"
    }
}