/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";
// import { addLocaleData } from "react-intl";
// import en from "react-intl/locale-data/en";
// import es from "react-intl/locale-data/es";

import "assets/scss/material-kit-pro-react.scss?v=1.7.0";

// pages for this product
// import AboutUsPage from "views/AboutUsPage/AboutUsPage.jsx";
// import BlogPostPage from "views/BlogPostPage/BlogPostPage.jsx";
// import BlogPostsPage from "views/BlogPostsPage/BlogPostsPage.jsx";
// import ComponentsPage from "views/ComponentsPage/ComponentsPage.jsx";
// import ContactUsPage from "views/ContactUsPage/ContactUsPage.jsx";
// import EcommercePage from "views/EcommercePage/EcommercePage.jsx";
// import LandingPage from "views/LandingPage/LandingPage.jsx";
// import LoginPage from "views/LoginPage/LoginPage.jsx";
import PresentationPage from "views/PresentationPage/PresentationPage.jsx";
// import PricingPage from "views/PricingPage/PricingPage.jsx";
// import ProfilePage from "views/ProfilePage/ProfilePage.jsx";
// import ProductPage from "views/ProductPage/ProductPage.jsx";
// import SectionsPage from "views/SectionsPage/SectionsPage.jsx";
// import ShoppingCartPage from "views/ShoppingCartPage/ShoppingCartPage.jsx";
// import SignupPage from "views/SignupPage/SignupPage.jsx";
// import ErrorPage from "views/ErrorPage/ErrorPage.jsx";

import * as serviceWorker from './serviceWorker';

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/* <Route path="/about-us" component={AboutUsPage} />
      <Route path="/blog-post" component={BlogPostPage} />
      <Route path="/blog-posts" component={BlogPostsPage} />
      <Route path="/components" component={ComponentsPage} />
      <Route path="/contact-us" component={ContactUsPage} />
      <Route path="/ecommerce-page" component={EcommercePage} />
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/pricing" component={PricingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/product-page" component={ProductPage} />
      <Route path="/sections" component={SectionsPage} />
      <Route path="/shopping-cart-page" component={ShoppingCartPage} />
      <Route path="/signup-page" component={SignupPage} />
      <Route path="/error-page" component={ErrorPage} /> */}
      <Route path="/" component={PresentationPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

serviceWorker.register();
